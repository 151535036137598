.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --pink: #F6D3C9;
  --gold: #DBAB56;
  --brown: #D1B38D;
  --yellowGray: #F9F6F2;
  --darkBrown: #906D38;
}

/**
    bauderBodoniStd roman in cottonbird
 */
.bodoni-moda-wedding {
  font-family: "Bodoni Moda", serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.capsule {
  height: 465px;
}

.pink-box {
  background-color: var(--pink);
  border-color: var(--brown);
  border-radius:0;
  border-style: solid;
  border-width: 2px;
  padding:  1em 2em;
  display: flex;
  position: relative;
  top: -151px;
  /*left: 110px;*/
  height: 200px;
  width: 90%;
  margin: 0 5%;
  z-index: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.flower-top {
  background-image: url("./../public/header-top.png");
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
  z-index: 0;
}

.flower-bottom {
  background-image: url("./../public/header-bottom.png");
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  position: relative;
  top: -315px;
  right: -35px;
  width: calc(100% - 35px);
  height: 297px;
  z-index: 0;
}

.golden-title {
  font-family: "Playfair Display", serif;
  font-weight: 500;
  color: var(--gold);
  font-size: xxx-large;
  display: flex;
  justify-content: center;
}

.wedding-information {
  width: 100%;
  padding: 32px;
  background-color: rgb(242, 237, 233);
  display: flex;
  justify-content: center;
  font-family: "Libre Baskerville", serif;
}

.wedding-date {
  font-family: "Playfair Display", "Montserrat", serif;
  font-size: xx-large;
  display: flex;

}

.languageSwitch {
  display: flex;
}

.column-preview {
  display: flex;
  justify-content: space-around;
  padding: 5%;
  font-family: "Libre Baskerville", serif;
}

h1 {
  font-family: "Libre Baskerville", serif;
  font-weight: 300;
  font-style: normal;
  font-size: x-large;
  color: darkred;
}

h2 {
  font-family: "Libre Baskerville", serif;
  font-weight: 300;
  font-style: normal;
  color: darkred;
}

h3 {
  font-family: "Libre Baskerville", serif;
  font-weight: 200;
  font-style: italic;

}

h4 {
  font-family: "Libre Baskerville", serif;
  font-weight: 200;
  font-style: italic;
  margin: 0.5em;

}

.ceremony {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reception {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirm-venue {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hotelLink {
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;

}